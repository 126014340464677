
























































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import store from "@/store";

@Component({})
export default class CountryContract extends Vue {
  private errors: any = {};
  private loading = false;
  private apiUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";
  private form: any = {};

  mounted() {
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
  }

  public async pay(): Promise<void> {
    this.loading = true;
    try {
      let form: any = this.$refs.form;
      const validate = form.validate();
      if (!validate) {
        return;
      }
      const res: AxiosResponse = await new UserApi().countryContract({
        ...this.form,
        country: "Italy"
      });
      console.log(res);
      window.location.href = res.data.url.action;
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response?.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    } finally {
      this.loading = false;
    }
  }
}
